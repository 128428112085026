import React from 'react';
import { Helmet } from 'react-helmet';
import useToggle from '../../Hooks/useToggle';
import heroThumb from '../../assets/images/screenshots/backoffice.jpg';
import BackToTop from '../BackToTop';
import FooterHomeThree from '../Footer/FooterHomeThree';
import HeaderHomeFour from '../Header/HeaderHomeFour';
import Drawer from '../Mobile/Drawer';
import FeaturesHomeOne from './FeaturesHomeOne';
import HeroHomeFour from './HeroHomeFour';
import PricingHomeTwo from './PricingHomeTwo';
import ServicesHomeOne from './ServicesHomeOne';
import ServicesHomeTwo from './ServicesHomeTwo';
import SponserHomeTwo from './SponserHomeTwo';

function HomeFour() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Helmet>
                <meta property="og:image" content={heroThumb} />
            </Helmet>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderHomeFour action={drawerAction.toggle} />
            <HeroHomeFour />
            <ServicesHomeOne className="pt-190" />
            <ServicesHomeTwo className="pt-100" />
            <FeaturesHomeOne className="bg-light" />
            <PricingHomeTwo />
            <SponserHomeTwo className="pt-90" />
            <FooterHomeThree className="home-four-footer" />
            <BackToTop className="back-to-top-3" />
        </>
    );
}

export default HomeFour;
