import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function ServicesHomeOne({ className }) {
    const [industry, setIndustry] = useState('hotels');

    const defaultCssClass = 'btn badge badge-pill btn-outline-secondary mx-1';

    return (
        <section className={`appie-service-area pt-90 pb-100 ${className}`} id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-7">
                        <div className="appie-section-title text-center">
                            <h2 className="appie-title">OTO Apps</h2>
                            <p>
                                To help businesses automate their core business processes, OTO
                                software includes various apps that will help you integrate
                                departments and functions, like accounting, procurement, sales,
                                inventory management and customer management
                            </p>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center my-2 service-nav flex-wrap">
                    <button
                        title="Hotel Solutions"
                        type="button"
                        onClick={() => setIndustry('hotels')}
                        className={`${defaultCssClass} ${industry === 'hotels' && 'active'}`}
                    >
                        Hotels
                    </button>
                    <button
                        title="Solutions for restaurants and Bars"
                        type="button"
                        onClick={() => setIndustry('resto')}
                        className={`${defaultCssClass} ${industry === 'resto' && 'active'}`}
                    >
                        Restaurants & Bars
                    </button>
                    <button
                        title="Solutions of Coffee Shops"
                        type="button"
                        onClick={() => setIndustry('coffee')}
                        className={`${defaultCssClass} ${industry === 'coffee' && 'active'}`}
                    >
                        Coffee Shops
                    </button>

                    <button
                        title="Solutions for Retail stores"
                        type="button"
                        onClick={() => setIndustry('retailers')}
                        className={`${defaultCssClass} ${industry === 'retailers' && 'active'}`}
                    >
                        Retailers
                    </button>

                    <button
                        title="Solutions of Freelancers"
                        type="button"
                        onClick={() => setIndustry('freelancers')}
                        className={`${defaultCssClass} ${industry === 'freelancers' && 'active'}`}
                    >
                        Freelancers
                    </button>
                </div>

                <div className="row justify-content-center">
                    {(industry === 'hotels' ||
                        industry === 'retailers' ||
                        industry === 'freelancers') && (
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="appie-single-service text-center mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <h4 className="appie-title">Backoffice</h4>
                                <p>Manage invoices, cashflow with ease using our backoffice app</p>
                            </div>
                        </div>
                    )}

                    {industry === 'hotels' && (
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <h4 className="appie-title">Frontdesk</h4>
                                <p>
                                    Manage rooms, reservations, bookings, guests with our frontdesk
                                    app
                                </p>
                            </div>
                        </div>
                    )}

                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <h4 className="appie-title">POS</h4>
                            <p>Streamline your sales process and improve customer experience</p>
                        </div>
                    </div>

                    {industry !== 'freelancers' && (
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="800ms"
                            >
                                <h4 className="appie-title">Inventory</h4>
                                <p>
                                    Designed to help you track your inventory levels in real-time.
                                </p>
                            </div>
                        </div>
                    )}
                </div>

                <div className="text-center mt-50">
                    <Link className="main-btn mt-1 mr-1" to="/apps">
                        Learn More <i className="fal fa-arrow-right"></i>
                    </Link>

                    <Link className="ml-1 mt-1 btn btn-outline-primary default-btn" to="/contact">
                        Get OTO today !
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne;
