import React from 'react';
import { Link } from 'react-router-dom';

function ServicesHomeTwo({ className }) {
    return (
        <>
            <section className={`appie-services-2-area pb-100 ${className}`} id="service">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-6 col-md-8">
                            <div className="appie-section-title">
                                <h3 className="appie-title">What OTO Offers</h3>
                                <p>Here are some features of OTO Software Suite</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-4">
                            <div className="appie-section-title text-right">
                                <Link className="main-btn" to="/apps">
                                    Browse Apps <i className="fal fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-file-invoice" />
                                </div>
                                <h4 className="title">Invoice Management</h4>
                                <p>
                                    Our app provides you with an intuitive interface for managing
                                    your invoices, making it easy to track payments, send reminders,
                                    and manage outstanding debts
                                </p>
                                <Link to="/apps/backoffice">
                                    Learn More <i className="fal fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-bed" />
                                </div>
                                <h4 className="title">Room Reservations</h4>
                                <p>
                                    Our app allows you to manage your room reservations in
                                    real-time, ensuring that your customers have access to
                                    up-to-date availability information and reducing the risk of
                                    overbooking.
                                </p>

                                <Link to="/apps/frontdesk">
                                    Learn More <i className="fal fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-cash-register" />
                                </div>
                                <h4 className="title">Integrated Point Of Sales</h4>
                                <p>
                                    Our POS app integrating our app with our inventory and hotel
                                    rooms management apps. This integration allows you to streamline
                                    your operations and have a complete view of your business in one
                                    place.
                                </p>
                                <Link to="/apps/pos">
                                    Learn More <i className="fal fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-pallet-alt" />
                                </div>
                                <h4 className="title">Inventory Management</h4>
                                <p>
                                    Simplifies inventory management by providing you with real-time
                                    updates on your stock levels and automating the reordering
                                    process to ensure you never run out of stock.
                                </p>
                                <Link to="/apps/inventory-management">
                                    Learn More <i className="fal fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-utensils-alt" />
                                </div>
                                <h4 className="title">Restaurant & Bar Orders</h4>
                                <p>
                                    Our app allows waiters to take orders directly from customers'
                                    tables, eliminating the need for pen and paper order-taking and
                                    reducing the potential for errors
                                </p>

                                <Link to="/apps/pos">
                                    Learn More <i className="fal fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-6 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-file-chart-line" />
                                </div>
                                <h4 className="title">Reports</h4>
                                <p>
                                    Our reports provide valuable insights into your business,
                                    allowing you to make data-driven decisions that can help you
                                    achieve your goals.
                                </p>

                                <Link to="/apps/backoffice">
                                    Learn More <i className="fal fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServicesHomeTwo;
