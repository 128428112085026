/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import heroBg from '../../assets/images/hero-bg-2.webp';
import heroThumb from '../../assets/images/screenshots/backpffoc-v2.webp';
import TrialModal from '../Modals/TrialModal';
import PopupVideo from '../PopupVideo';

function HeroHomeFour() {
    const [showVideo, setVideoValue] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const handleShowVideo = (e) => {
        e.preventDefault();
        setVideoValue(!showVideo);
    };
    return (
        <>
            {showVideo && (
                <PopupVideo
                    videoSrc="//www.youtube.com/embed/cMHC2oCSOc4?autoplay=1"
                    handler={(e) => handleShowVideo(e)}
                />
            )}

            {showModal && (
                <TrialModal show={showModal} onRequestClose={() => setShowModal(false)} />
            )}
            <section className="appie-hero-area appie-hero-5-area appie-hero-3-area">
                <link rel="preload" as="image" href={heroBg}></link>
                <link rel="preload" as="image" href={heroThumb}></link>

                <div className="container">
                    <div className="row  justify-content-center">
                        <div className="col-lg-10">
                            <div className="appie-hero-content text-center">
                                <h1 className="appie-title">Advanced Business Software Suite</h1>
                                <p>
                                    OTO, a software suite by{' '}
                                    <a
                                        style={{ textDecoration: 'underline' }}
                                        className="text-white"
                                        target="_blank"
                                        href="https://kapp.rw"
                                    >
                                        Kapp
                                    </a>
                                    , is designed to streamline business processes,
                                    <br />
                                    improve efficiency, reduce costs, and increase productivity.
                                </p>
                                <div className="hero-btns">
                                    <button
                                        type="button"
                                        className="main-btn"
                                        onClick={() => {
                                            setShowModal(true);
                                        }}
                                    >
                                        Free Trial
                                    </button>
                                    <a
                                        className="appie-video-popup"
                                        href="tel:+250788381832"
                                        data-href="https://www.youtube.com/watch?v=cMHC2oCSOc4?autoplay=1"
                                    >
                                        <i className="fas fa-phone"></i>{' '}
                                        <a className="text-white" href="tel:+250788381832">
                                            <span style={{ textDecoration: 'underline' }}>
                                                +250 788 381 832
                                            </span>
                                        </a>
                                    </a>
                                </div>
                                <div
                                    className="thumb mt-80 wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="400ms"
                                >
                                    <img
                                        src={heroThumb}
                                        alt="OTO Software Suite"
                                        title="OTO Software Suite"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroHomeFour;
