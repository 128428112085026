import React from 'react';
import { Link } from 'react-router-dom';
import featuresImage from '../../assets/images/features-thumb.png';

function PricingHomeTwo() {
    return (
        <>
            <section className="appie-pricing-2-area pt-70 pb-90">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-6 col-lg-4 col-sm-6 mb-5 text-center text-md-right text-lg-right mb-md-0">
                            <img
                                style={{ maxHeight: '500px' }}
                                src={featuresImage}
                                alt="OTO Software Suite Pricing"
                            />
                        </div>
                        <div className="col-md-6 col-lg-8">
                            <p className="text-primary mb-3">
                                Get a personalized pricing plan that works for your budget and goals
                            </p>
                            <h3>
                                Save Money and Streamline Your Operations with <br />
                                Our Competitive Pricing
                            </h3>
                            <p className="mb-3 mt-3">
                                Our pricing is transparent, straightforward, and tailored to your
                                specific needs and requirements. We believe that by providing
                                cost-effective solutions, we can help our clients achieve their
                                goals and succeed in their business.
                            </p>
                            <p>
                                But don't just take our word for it! Contact us today to request a
                                price quote for our business software solutions. Our team of experts
                                will work closely with you to understand your unique needs and
                                develop a customized solution that fits within your budget.
                            </p>

                            <Link className="main-btn mt-4" to="/contact">
                                Get a Quote <i className="fal fa-arrow-right"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PricingHomeTwo;
