import React from 'react';
import Sponser6 from '../../assets/images/classic-logo.jpg';
import Sponser5 from '../../assets/images/greatseasons-logo.jpg';
import Sponser2 from '../../assets/images/lapalme-logo.png';
import Sponser1 from '../../assets/images/lenima.jpeg';
import Sponser3 from '../../assets/images/scheba-logo.png';
import Sponser4 from '../../assets/images/urban-logo.jpg';

function SponserHomeTwo({ className }) {
    return (
        <>
            <section className={`appie-sponser-area mb-10 pb-150 ${className}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Some of our customers</h3>
                                <p>
                                    Our satisfied customers span a wide range of industries and
                                    business sizes
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-sponser-box d-flex justify-content-center">
                                <div className="sponser-item">
                                    <img
                                        src={Sponser5}
                                        title="Great Seasons Hotel"
                                        alt="Great Seasons Hotel"
                                    />
                                </div>

                                <div className="sponser-item">
                                    <img
                                        style={{ maxWidth: '60px' }}
                                        title="Classic Hotel"
                                        src={Sponser6}
                                        alt="Classic"
                                    />
                                </div>
                                <div className="sponser-item">
                                    <img
                                        title="Urban Park Suites Hotel"
                                        src={Sponser4}
                                        alt="Urban Park"
                                    />
                                </div>
                                <div className="sponser-item">
                                    <img src={Sponser3} title="Scheba" alt="Scheba" />
                                </div>
                                <div className="sponser-item">
                                    <img title="La Palme" src={Sponser2} alt="LaPalme" />
                                </div>

                                <div className="sponser-item">
                                    <img title="Lenima" src={Sponser1} alt="LaPalme" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SponserHomeTwo;
